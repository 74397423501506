import React, { useState }  from 'react'
import "../css/Plants.css"
import data from "./Data"
import "../css/pop.css"
import "../responsive/Plantsres.css"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Zoom, Navigation, Pagination } from "swiper";

// Import Swiper styles

import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";


function Plants({handleAddProduct}) {
  const [popupcontent, setpopupcontent]=useState([])
  const [popuptogle, setpopuptogle] = useState(false)
const {productItems}=data
const changecontent=(productItems)=>{
  setpopupcontent([productItems])
  setpopuptogle(!popuptogle)
}
  return (
    <>
    <div className="container">
     <h2 className="pro">Բույս <br /> սուկուլենտերի հավաքածու </h2>
      <div className="products">
       
      {
        productItems.map((productItems)=>(
            <div className="card" key={productItems.id}>
              <div onClick={()=>changecontent(productItems)}>
                 <img className="product-image" src={productItems.image} alt="" />
              </div>
             <div className="product-names">
             <span  className="cart-items_name-code">Կոդ: {productItems.code}</span>
              <span className="product-price">{productItems.price} ֏ </span>
             </div>
              <div>
              <button className="product-btn" onClick={()=>handleAddProduct(productItems)}>Ավելացնել <i class="fas fa-shopping-bag"></i></button>
              </div>
            </div>
             
          
        ))
      } 
      </div>
       {popuptogle && 
       (<div className="pop_up_container" >
          <div className="pop_up_body" >
          <div className="pop_up_header" >
            <button className="pop-btn" onClick={changecontent}>X</button>
          </div>
          <div className="pop_up_content">
            {
              popupcontent.map((pop)=>{
                  return(
                    <div className="pop_up_card" key={pop.id}>
                        <div>
                        <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        zoom={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[Zoom, Navigation, Pagination]}
        className="mySwiper Swiper-pop"
      >
        <SwiperSlide>
          <div className="swiper-zoom-container">
            <img className="zoom-img" src={pop.image} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-zoom-container">
            <img className="zoom-img" src={pop.image} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-zoom-container">
            <img  className="zoom-img" src={pop.image} />
          </div>
        </SwiperSlide>

      </Swiper>
                        </div>
                 


                    </div>
                  )
              })
            }
          </div>
          </div>
       
        </div>
       )}
       </div>
    </>
  )
}

export default Plants