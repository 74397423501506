import React from 'react'
import "../css/Cart.css"
import "../responsive/Cartres.css"
import empty from "../images/empty.png"
function Cart({cartItems,handleAddProduct,handleRemoveProduct,clearCart}) {
  const totalPrice=cartItems.reduce((price,item)=>price+ item.quantity*item.price,0)
  return (
<>
<div className="container-cart">

    <div className="cart-items">
     
      <div className="clear-card">
        {
          cartItems.length>=1 &&(
            <button  className="clear-card_btn" onClick={()=>clearCart()}>Հեռացնել բոլորը </button>
          )
        }
        </div>
      {cartItems.length===0 && 
      <div className="cart-item_empaty">
        <p>Ձեր զամբյուղը դատարկ է </p>
        <img src="https://i0.wp.com/www.huratips.com/wp-content/uploads/2019/04/empty-cart.png?resize=603%2C288&ssl=1" alt="" />
      </div>
        
      }
      <div>
      {
        cartItems.map((item)=>(
        <div className="cart-item_list" key={item.id}>
          <div  className="cart-item_image"><img  src={item.image} alt={item.name} /></div>  
            <div className="cart-item_info">
            <div className="cart-items_name">
               <span  className="cart-items_name-price">Արժեք:{item.price}</span> 
                <span  className="cart-items_name-code">Կոդ:{item.code}</span>
            </div>
           
            <div className="card-items_function">
               <div className="btn-pluse_minus"><button className="card-items-add" onClick={()=>handleAddProduct(item)}>+</button>
                <button className="card-items-remove"onClick={()=>handleRemoveProduct(item)}>-</button></div> 
                <div><span> {item.quantity}</span></div>
            </div>
  

            </div>
          </div>
          
        ))
      }

</div>
    </div>

    {
          cartItems.length>=1 &&(
            <div className="cart-items_total-price-name">


<div className="cart-items_total-price">
<b>Ընդհանուր արժեքը  </b>  {totalPrice} ֏
              </div>
              <a href="tel:+37499119102"> <button className="btn-coll">Պատվիրել</button> </a>

            </div>
            
          )
          
        }


</div>
    </>
  )
}

export default Cart