import React from 'react'
import "../css/About.css"
import "../responsive/Aboutres.css"
import about1 from "../images/about1.jpg"
import about2 from "../images/about1.1.jpg"
import about3 from "../images/about1.2.jpg"
import about4 from "../images/about1.3.jpg"

function About() {
  return (
    <>
    <div className="about">
      <h1 className="about-succulent">ՍՈւԿՈւԼԵՆՏՆԵՐԻ ՄԱՍԻՆ</h1>
      <br />
      <div className="about1">
        <div className="about-img">
          <img src={about1} alt="" />
        </div>

          <p className="about1_text">
          Սուկուլենտ (լատիներեն նշանակում է հյութալի): Որպես կանոն, նրանք աճում են չոր կլիմա ունեցող վայրերում, պայծառ արևի տակ:
Սուկուլենտների ներկայացուցիչները միմյանց հետ կապված չեն իրենց ծագումով, բույսերի կապվածությունը պայմանավորված է նրանց բնակության բնակլիմայական պայմաններով, վայրերով:
Սուկուլենտը  ազգ չէ, տեսակ չէ, դա հասկացություն է, որը կապում է տարբեր բույսերի տեսակներին նմանատիպ պայմաններում: Աճելու, գոյատևելու նմանությամբ սուկուլենտները տարբեր ընտանիքների և տարբեր տարածաշրջանների բույսեր են: Չնայած այն հանգամանքին, որ աշխարհում կա մոտավորապես 10 հազար սուկուլենտ, դրանց մեծ մասը պատկանում է ընդամենը վեց ընտանիքի.
  <br />
  <br />
    <ul className="about1_text-list">
      <li  className="about1_text-item">1.кактусовые (Cactaceae)</li>
      <li  className="about1_text-item">2.ластовневые (Asclepiadaceae)</li>
      <li  className="about1_text-item">3.асфоделовые (Asphodelaceae)</li>
      <li  className="about1_text-item">4.толстянковые (Crassulaceae)</li>
      <li  className="about1_text-item">5.молочайные (Euphorbiaceae)</li>
      <li  className="about1_text-item">6.мезембриантемовые (Mesembryanthemaceae)</li>
    </ul>
    <br />
Սուկուլենտները, ըստ էության, անապատային բույսեր են, նրանք սիրում են չոր օդը և ջերմաստիճանի փոփոխությունները: 
Տնային պայմաններում բույսի  աճեցման համար  բավականաչափ լավ է տարածքը օդափոխել, խոնավ տեղում չպահել, փորձեք ապահովել ցերեկային և գիշերվա ջերմաստիճանի առավելագույն տատանումները և հիշեք, որ նրանք իհարկե  չեն սիրում սառնամանիքները:
          </p>
      </div>

      <div className="about2">
        <p className="about2_text">
         <span >Լուսավորություն</span> 
         <br />
Թեժ արևը ցանկացած անապատի անբաժանելի հատկանիշն է, ուստի այս բույսերը սիրում են լույսը: Լավագույնն այն է. տեղադրել հարավում, հարավ-արևելքում կամ հարավ-արևմուտքում գտնվող պատուհանագոգին: Եթե պատուհանները  հյուսիսային կողմում են, ամենայն հավանականությամբ կպահանջվի  լրացուցիչ լուսավորություն:
Բոլոր սուկուլեմտները սիրում են լույսի առատություն, բայց  ոչ բոլորն  ունեն նույն  արևի լույսի հանդեպ նույն վերաբերմունքը: Այսպիսով, օրինակ բնության կակտուսները և ագավան աճում են բաց տարածքներում, բայց Գաստերիան՝ հալվեի որոշ տեսակներ, կրասուլան  ուղղակի արևի  ճառագայթները պահանջում են միայն առավոտյան և երեկոյան: Ցերեկային ժամերին  այս տեսակները կարող են այրվել: Ահա թե ինչու մենք խորհուրդ ենք տալիս մանրամասն ուսումնասիրել  ձեր իսկ կողմից ձեռք բերված սուկուլենտ տեսակի բույսի վերաբերմունքը արևի ճառագայթների հանդեպ:</p>
        <div className="about-img">
          <img src={about2} alt="" />
        </div>
      </div>

      <div className="about3">
        <div className="about-img">
          <img src={about3} alt="" />
        </div>
        <p className="about3_text">
      <span>Ջրում</span>  
      <br />
Առաջին հերթին, դա տարբեր կլինի կախված սեզոնից, ջերմաստիճանից և խոնավությունից:
Գարնանից մինչև աշնան աճի ժամանակահատվածում ջրելը կատարվում է մոտավորապես 1-2 շաբաթը մեկ անգամ,
ձմռանը՝ քնած ժամանակ, ջրելը պետք է իրականացվի ավելի քիչ՝ ամիսը մեկ անգամ 10-14 ° C ջերմաստիճանում կամ 2-3 ամիսը մեկ 6-9 ° C ջերմաստիճանում:
Իսկ արդեն շատ շոգ եղանակին  բույսերը պետք է ավելի հաճախ ջրվեն, բայց հողը չոր պետք է մնա նվազագույնը 3 օր:
Նրանց համար հարմար հողը նախ և առաջ պետք է լինի փխրուն,   հավասարաչափ խոնավեցվի և ջուր չպահպանի: 
Ցանկալի է երիտասարդ բույսերը տեղափոխել տարին մեկ անգամ, մեծահասակները` 2-3 տարին մեկ անգամ: 
        </p>
      </div>
      
      <div className="about4">
      <p className="about4_text">
        <span>Պարարտանյութեր</span>
        <br />
        Ինչպես ցանկացած բույս, սուկուլենտները կարիք ունեն  ոչ միայն ջրելու և տեղափոխելու, այլև  պարբերաբար սննդանյութերի կերակրման: Դուք կարող եք հեշտությամբ գտնել պատրաստի տարբերակներ ցանկացած ծաղկի խանութում: Կերակրման ժամանակ պահպանեք հետևյալ կանոնները.
<br />
<ul>
  <li>ձմռանը ավելի լավ է չպարարտացնել սուկուլենտները, երբ նրանք հանգստանում են,</li>
  <li>պարարտացումը  սովորաբար կատարվում է գարնանը  ոչ ավելի, քան ամիսը մեկ անգամ,</li>
  <li>պարարտանյութերի կոնցենտրացիան պետք է պակաս լինի, քան մյուս սենյակային  բույսերի համար,</li>
  <li>պարարտանյութերում ազոտի պարունակությունը պետք է կրճատվի, որպեսզի չառաջանա արմատների փտում:</li>
</ul>
      </p>
        <div className="about-img">
          <img src={about4} alt="" />
        </div>
      </div>
    </div>
    </>
  )
}

export default About