import React, { useState } from 'react'
import Header from "./components/Header"


function App() {
  const [cartItems,setCartItems]=useState([])

  const handleAddProduct=(product)=>{
    const ProductExist= cartItems.find((item)=>item.id==product.id)
    if(ProductExist){
      setCartItems(cartItems.map((item)=>item.id==product.id?
      {...ProductExist, quantity:ProductExist.quantity+1}:item)
      ) ;
    }
    else{
      setCartItems([...cartItems,{...product,quantity:1}]);
   
  };
  }
const handleRemoveProduct =(product)=>{
  const ProductExist= cartItems.find((item)=>item.id==product.id)
  if(ProductExist.quantity===1){
    setCartItems(cartItems.filter((item)=>item.id !== product.id))
  }else{
    setCartItems(
      cartItems.map((item)=> item.id===product.id?
      {...ProductExist, quantity:ProductExist.quantity-1}:item
      )
    )
  }
}

const clearCart=()=>{
    setCartItems([])
}

  return (
    <div>
      <Header cartItems={cartItems} 
      handleAddProduct={handleAddProduct} 
      handleRemoveProduct={handleRemoveProduct} 
      clearCart={clearCart}
      />
      </div>
  )
}

export default App