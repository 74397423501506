import React from 'react'
import { NavLink,Link ,Outlet} from "react-router-dom"

import logo from "../images/logo.png"
import websoft from "../images/websoft.png"
import "../css/Layout.css"
import "../responsive/Leyouteres.css"

function Layoute({cartItems}) {
  return (
    <>
        <div className="header">
            <div className="logo">
            <NavLink className="cool-link item_link" to="/"><img src={logo} alt="" /></NavLink>

            </div>
            <div className="header-lists">
                <ul className="header-list"> 
                    <li className="header-list_item"> <NavLink className="cool-link item_link" to="/">Գլխավոր էջ</NavLink></li>
                    <li className="header-list_item"> <NavLink className="cool-link item_link" to="/aboutUs"> Մեր մասին  </NavLink></li>
                    <li className="header-list_item"> <NavLink className="cool-link item_link" to="/about">Սուկուլենտներ</NavLink></li>
                </ul>
            </div>
            <div className="global-icon">
            <div className="header-icons">
            <a href="https://www.facebook.com/SuculentsHouse/" target="_blank"><i class="fab fa-facebook-f"></i></a> 
            <a href="https://www.instagram.com/succulentshousea/" target="_blank"><i class="fab fa-instagram"></i></a>
            <a href="tel:+37499119102" ><i class="fas fa-phone"></i></a>
            </div>
            <div className="header-shop">
           <Link to="/cart" className="header-shop_link"> <i class="fas fa-shopping-bag"></i></Link>
           <span className="cart-length">

               {cartItems.length=== 0 ? "0":cartItems.length}
           </span>
            </div>
            </div>
        </div>
    <main className="roote" >
    <Outlet/>

    </main>
        <footer>
            <div className="footer-global">
            <div className="section-footer">
            <div className="logo-footer">
                <NavLink className="cool-link item_link" to="/"><img src={logo} alt="" /></NavLink>
                </div>
         
            <div className="footer-lists">
                <ul className="footer-list"> 
                    <li className="footer-list_item"> <NavLink className="cool-link item_link" to="/">Գլխավոր էջ</NavLink></li>
                    <li className="footer-list_item"> <NavLink className="cool-link item_link" to="/aboutUs">Մեր մասին</NavLink></li>
                    <li className="footer-list_item"> <NavLink className="cool-link item_link" to="/about">Սուկուլենտներ</NavLink></li>
                </ul>
            </div>
            
               <div className="footer-icons_end">
                   <div className="footer-icons">
                   <a href="https://www.facebook.com/SuculentsHouse/" target="_blank"><i class="fab fa-facebook-f"></i></a> 
            <a href="https://www.instagram.com/succulentshousea/" target="_blank"><i class="fab fa-instagram"></i></a>
            <a href="tel:+37499119102" ><i class="fas fa-phone"></i></a>
            <a href="https://api.whatsapp.com/send?phone=+37498003366"  target="_blank"><i class="fab fa-whatsapp"></i></a>
            <a  href="https://msng.link/o/?37498003366=vi"  target="_blank"><i class="fab fa-viber"></i></a>
            <a  href="mailto:tatevzaqevosyan94@gmail.com"><i class="fas fa-envelope"></i></a>
                   </div>
     
          
       </div>
       <div className="websoft">
                    <p>Դիզայնը և ծրագրավորումը</p>
                  <a href="https://websoft.am/" target="_blank"><img src={websoft} alt="" /></a> 
               </div>
           
            </div>
      
            

       
       <div className="footer_end">
         <p className="footer-text">© Succulents House 2022</p>   
       </div>
       
       </div>
        </footer>
    </>
  )
}

export default Layoute