import React, { useRef, useState } from 'react'
import "../css/Home.css"
import  "../responsive/Homeres.css"

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Pagination, Navigation } from "swiper";


import {Routes,Route,Link} from "react-router-dom"
import Plants from "./Plants"
import Composition from "./Composition"
import Officeplants from "./Officeplants"
import Accessories from "./Accessories"
import AboutUs from './AboutUs';

import car from "../images/cars.png"
import ezaki1 from "../images/ezaki1.jpg"
import ezaki2 from "../images/ezaki2.jpg"
import ezaki3 from "../images/ezaki3.jpg"
import ezaki4 from "../images/ezaki4.jpg"

import bg from "../images/back.jpg"

import prod from "../images/bg.jpg"

function Home() {
  return (
    <>
    {/* start slider */}
    <section className="slider-section"> 
    <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide className="SwiperSlide carusel">
          <img src={bg} alt=""  />
        </SwiperSlide>
        <SwiperSlide className="SwiperSlide carusel">
        <img src={bg} alt="" />
        </SwiperSlide>

      </Swiper>
 
    </section>
     {/* end slider */}

      {/* start product */}
  <section className="services section-product">

    <div className="content">
        <Link to="/plants" className="box product-link"> <div className="inner products-item">
                <img className=" products-item_img" src={prod}  alt=""  />
                <p className="product-name_home"> Բույս <br/>սուկուլենտերի հավաքածու </p>
            </div></Link>
        <Link to="/composition " className="box product-link"><div className="inner products-item">
        <img className=" products-item_img" src={prod}  alt=""  />
        <p className="product-name_home">Կոմպոզիցիա <br/> նվերներ</p>
        
          </div> </Link>
        <Link to="/officeplants" className="box product-link"><div className="inner products-item">
        <img className=" products-item_img" src={prod}  alt=""  />
        <p className="product-name_home">Սենյակային, գրասենյակային բույսեր</p>
            </div></Link>
        <Link to="/accessoies" className="box product-link"><div className="inner products-item">
        <img className=" products-item_img" src={prod}  alt=""  />
        <p className="product-name_home">Ծաղկաման<br/> դեկորներ <br/>ակսեսուարներ    </p>
        
            </div></Link>

    </div>
    <Routes>
        <Route path="/plants" element={<Plants/>}/>
        <Route path="/composition" element={<Composition/>}/>
        <Route path="/officeplants" element={<Officeplants/>}/>
        <Route path="/accessoies" element={<Accessories/>}/>

        </Routes> 
    </section>
 {/* end product */}


      {/* start aboutUs */}

      <AboutUs/>
 
       {/* end aboutUs */}

{/* start Shipment */}

<section className="section-shipment">
<div className="shipment">
<div className="shipment-info">
    <p className="shipment-info_text">
     <span className="shipment-info_text1"> ԱՌԱՔՈւՄ </span>
      <br />
Գործում է առաքում ՀՀ ամբողջ տարածքում 
Երևանում առաքումն արժե 500 դրամ
Երևանից դուրս կատարվում է Yandex ծառայության միջոցով իսկ որոշ դեպքերում` մասնավորապես հատավաճառի, նաև փոստային տարբերակով:</p>
 <a href="tel:+37499119102"> <button className="shipment-info_btn">Պատվիրել</button> </a>
</div>
<div className="shipment-img">
  <img src={car} alt="" />
</div>
</div>
</section>

{/* end Shipment */}


{/* start unique designs */}

<section className="section-unique_designs">
      <div>
        <p className="unique-designs_text"> Եզակի նմուշներ</p> 
      </div>
       <Swiper
       slidesPerView={5}
        spaceBetween={30}
        slidesPerGroup={3}
        loop={true}
        loopFillGroupWithBlank={true}
        // pagination={{
        //   clickable: true,
        // }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper mySwiper2"
      

>
      
      
        <SwiperSlide  className="unique-designs_img">
        <img src={ezaki1} alt="" />
        <div class="insta-icon"> 
        <a href="https://www.instagram.com/succulentshousea/" target="_blank" ><i class="fab fa-instagram"></i></a>
        </div>
        </SwiperSlide>
        <SwiperSlide  className="unique-designs_img">
        <img src={ezaki2} alt="" />
        <div class="insta-icon"> 
        <a href="https://www.instagram.com/succulentshousea/" target="_blank" ><i class="fab fa-instagram"></i></a>
        </div>
        </SwiperSlide>
        <SwiperSlide  className="unique-designs_img">
        <img src={ezaki3} alt="" />
        <div class="insta-icon"> 
        <a href="https://www.instagram.com/succulentshousea/" target="_blank" ><i class="fab fa-instagram"></i></a>
        </div>
        </SwiperSlide>
        <SwiperSlide  className="unique-designs_img">
        <img src={ezaki4} alt="" />
        <div class="insta-icon"> 
        <a href="https://www.instagram.com/succulentshousea/" target="_blank" ><i class="fab fa-instagram"></i></a>
        </div>
        </SwiperSlide>
        <SwiperSlide  className="unique-designs_img">
        <img src={ezaki1} alt="" />
        <div class="insta-icon"> 
        <a href="https://www.instagram.com/succulentshousea/" target="_blank" ><i class="fab fa-instagram"></i></a>
        </div>
        </SwiperSlide>
        <SwiperSlide  className="unique-designs_img">
        <img src={ezaki1} alt="" />
        <div class="insta-icon"> 
        <a href="https://www.instagram.com/succulentshousea/" target="_blank" ><i class="fab fa-instagram"></i></a>
        </div>
        </SwiperSlide>
      </Swiper>
</section>

{/* end unique designs */}

    </>
  )
}

export default Home