import img1 from "../images/home.jpg"

const compositionData={
    compositionItems:[
        {
            id:10,
            name:"Կոմպոզիցիա ",
            price:15000,
            image:img1,
            code:900
        },
        {
            id:11,
            name:"Կոմպոզիցիա ",
            price:15000,
            image:img1,
            code:901
        },
        {
            id:12,
            name:"Կոմպոզիցիա ",
            price:15000,
            image:img1,
            code:902
        },
        {
            id:13,
            name:"Կոմպոզիցիա ",
            price:15000,
            image:img1,
            code:903
        },
        {
            id:14,
            name:"Կոմպոզիցիա ",
            price:15000,
            image:img1,
            code:904
        },
        {
            id:15,
            name:"Կոմպոզիցիա ",
            price:15000,
            image:img1,
            code:905
        },  
         {
            id:16,
            name:"Կոմպոզիցիա ",
            price:15000,
            image:img1,
            code:906
        },
        {
            id:17,
            name:"Կոմպոզիցիա ",
            price:15000,
            image:img1,
            code:907
        },
        {
            id:18,
            name:"Կոմպոզիցիա ",
            price:15000,
            image:img1,
            code:908
        }
    ]
}

export default compositionData;