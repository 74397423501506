import img1 from "../images/caxkaman.jpg"

const accessoriesData={
    accessoriesItems:[
        {
            id:28,
            name:"Ծաղկաման ",
            price:17000,
            image:img1,
            code:800
        },
        {
            id:29,
            name:"դեկոր ",
            price:17000,
            image:img1,
            code:801
        },
        {
            id:30,
            name:"ակսեսուար ",
            price:17000,
            image:img1,
            code:802
        },
        {
            id:31,
            name:"Ծաղկաման ",
            price:17000,
            image:img1,
            code:803
        },
        {
            id:32,
            name:"դեկոր ",
            price:17000,
            image:img1,
            code:804
        },
        {
            id:33,
            name:"ակսեսուար ",
            price:17000,
            image:img1,
            code:805
        },  
         {
            id:34,
            name:"Ծաղկաման ",
            price:17000,
            image:img1,
            code:806
        },
        {
            id:35,
            name:"դեկոր ",
            price:17000,
            image:img1,
            code:807
        },
        {
            id:36,
            name:"ակսեսուար ",
            price:17000,
            image:img1,
            code:808
        }
    ]
}

export default accessoriesData;