import img1 from "../images/house.jpg"

const officeplantsData={
    officeplantsItems:[
        {
            id:19,
            name:"Սենյակային ",
            price:9000,
            image:img1,
            code:800
        },
        {
            id:20,
            name:"գրասենյակային ",
            price:9000,
            image:img1,
            code:801
        },
        {
            id:21,
            name:"Սենյակային ",
            price:9000,
            image:img1,
            code:802
        },
        {
            id:22,
            name:"գրասենյակային ",
            price:9000,
            image:img1,
            code:803
        },
        {
            id:23,
            name:"Սենյակային ",
            price:9000,
            image:img1,
            code:804
        },
        {
            id:24,
            name:"գրասենյակային ",
            price:9000,
            image:img1,
            code:805
        },  
         {
            id:25,
            name:"Սենյակային ",
            price:9000,
            image:img1,
            code:806
        },
        {
            id:26,
            name:"գրասենյակային ",
            price:9000,
            image:img1,
            code:807
        },
        {
            id:27,
            name:"Սենյակային ",
            price:9000,
            image:img1,
            code:808
        }
    ]
}

export default officeplantsData;