import React, { useState } from 'react'
import accessoriesData from './AccessoriesData'
import "../css/pop.css"
import "../responsive/Plantsres.css"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Zoom, Navigation, Pagination } from "swiper";


function Accessories({handleAddProduct}) {
  const {accessoriesItems}=accessoriesData
  const [popupcontent, setpopupcontent]=useState([])
  const [popuptogle, setpopuptogle] = useState(false)

const changecontent=(productItems)=>{
  setpopupcontent([productItems])
  setpopuptogle(!popuptogle)
}
  return (
    <>
        <div className="container">

    <h2 className="pro">Ծաղկաման <br /> դեկորներ <br /> ակսեսուարներ  </h2>

    <div className="products">
    {
      accessoriesItems.map((accessoriesItems)=>(
          <div className="card" key={accessoriesItems.id}>
            <div onClick={()=>changecontent(accessoriesItems)}> 
               <img className="product-image" src={accessoriesItems.image} alt="" />
            </div>
           <div className="product-names">
           <span  className="cart-items_name-code">Կոդ: {accessoriesItems.code}</span>
            <span className="product-price">{accessoriesItems.price} ֏</span>
           </div>
            <div>
            <button className="product-btn" onClick={()=>handleAddProduct(accessoriesItems)}>Ավելացնել  <i class="fas fa-shopping-bag"></i></button>
            </div>
          </div>
           
        
      ))
    } 
    </div>
    {popuptogle && 
       (<div className="pop_up_container" >
          <div className="pop_up_body">
          <div className="pop_up_header">
            <button className="pop-btn" onClick={changecontent}>X</button>
          </div>
          <div className="pop_up_content">
            {
              popupcontent.map((pop)=>{
                  return(
                    <div className="pop_up_card" key={pop.id}>
                        <div>
                        <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        zoom={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[Zoom, Navigation, Pagination]}
        className="mySwiper Swiper-pop"
      >
        <SwiperSlide>
          <div className="swiper-zoom-container">
            <img className="zoom-img" src={pop.image} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-zoom-container">
            <img className="zoom-img" src={pop.image} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-zoom-container">
            <img className="zoom-img" src={pop.image} />
          </div>
        </SwiperSlide>

      </Swiper>
                        </div>
                 


                    </div>
                  )
              })
            }
          </div>
          </div>
       
        </div>
       )}
    </div>
  </>
  )
}

export default Accessories