import React from 'react'
import {Routes,Route} from "react-router-dom"
import About from './About'
import Home from './Home'
import Plants from "./Plants"
import Composition from "./Composition"
import Officeplants from "./Officeplants"
import Accessories from "./Accessories"
import Cart from "./Cart"
import Layoute from './Layoute'
import AboutUs from './AboutUs'

function header({cartItems,handleAddProduct,handleRemoveProduct,clearCart}) {


  return (
      <>

        <Routes>
            <Route path="/" element={<Layoute cartItems={cartItems}/>} >
            <Route index path="" element={<Home />}/>
            <Route path="aboutUs" element={<AboutUs/>}></Route>
            <Route path="about" element={<About/>}/>
            <Route path="plants" element={<Plants handleAddProduct={handleAddProduct}/>}/>
            <Route path="composition" element={<Composition  handleAddProduct={handleAddProduct}/>}/>
            <Route path="officeplants" element={<Officeplants handleAddProduct={handleAddProduct}/>}/>
            <Route path="accessoies" element={<Accessories handleAddProduct={handleAddProduct}/>}/> 
            <Route path="cart" element={
            <Cart cartItems={cartItems} 
            handleAddProduct={handleAddProduct} 
            handleRemoveProduct={handleRemoveProduct}
            clearCart={clearCart}
            />} />
            </Route>
        </Routes> 
    </>
    )
}

export default header