import React from 'react'
import "../css/Home.css"
import aboutimg from "../images/about.jpg"
import "../responsive/Homeres.css"

function AboutUs() {
  return (
    <>
         <section id="aboutUs">
      <div className="aboutUs">
      <p className="about-us-h">Մեր մասին</p>
      <div className="about-us">
          <div className="about-us_img">
            <img src={aboutimg} alt="" />
          </div>
          <div className="about-us_text">
          <p>Succulents House-ը օնլայն խանութ է, որն իր գործունեությունը`մասնավորապես սուկուլենտների վաճառքը սկսել է 2020 թվականից: Սկսել է 7 տարվա անձնական հավաքածուի ստեղծման, բազմացման, աճեցման փորձից, ինչով այսօր պատրաստ ենք կիսվել ձեզ հետ: Succulents House բրենդի օնլայն հարթակում մինչ օնլայն վաճառքի անցնելը բույսերը մեծ դժվարություններով ներկրվել են տարբեր երկրներից, ուսումնասիրվել, ադապտացվել Հայաստանի կլիմայական պայմաններին: Այս 7 տարիների ընթացքում հնարավոր դարձավ ստեղծել մեզ և ձեզ համար մոտ 150 տեսակ բույսերով հավաքածուն, որի եզակիությունից, գեղեցկությունից այսօր կարող եք ունենալ նաև դուք:</p>

          </div>
      </div>
    </div>
 </section>

    </>
  )
}

export default AboutUs