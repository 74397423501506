import img1 from "../images/buys.jpg"

const data={
    productItems:[
        {
            id:1,
            name:"Բույս ",
            price:11000,
            image:img1,
            code:800
        },
        {
            id:2,
            name:"Բույս ",
            price:11000,
            image:img1,
            code:801
        },
        {
            id:3,
            name:"Բույս ",
            price:11000,
            image:img1,
            code:802
        },
        {
            id:4,
            name:"Բույս ",
            price:11000,
            image:img1,
            code:803
        },
        {
            id:5,
            name:"Բույս ",
            price:11000,
            image:img1,
            code:804
        },
        {
            id:6,
            name:"Բույս ",
            price:11000,
            image:img1,
            code:805
        },  
         {
            id:7,
            name:"Բույս ",
            price:11000,
            image:img1,
            code:806
        },
        {
            id:8,
            name:"Բույս ",
            price:11000,
            image:img1,
            code:807
        },
        {
            id:9,
            name:"Բույս ",
            price:11000,
            image:img1,
            code:808
        }
    ]
}

export default data;